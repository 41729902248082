import React from 'react';

import GraduatedLine from '@Components/graduatedLine/GraduatedLine';

import './HumanData.scss';

export default class HumanData extends React.Component {
	constructor(props){
		super(props);
	
		this.state = {
			years: null,
			days: null,
			uptime: null,
			power: null,
			data1: [],
			data2: []
		}
	}

	componentDidMount() {
		this.setTimePassed();
		this.setPower();
		this.drawCurve('canvas1', this.state.data1);
		this.drawCurve('canvas2', this.state.data2);

		this.interval = setInterval(() => {
			this.setTimePassed();
			this.setPower();
			this.drawCurve('canvas1', this.state.data1);
			this.drawCurve('canvas2', this.state.data2);
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	setPower() {
		let power = 'sleep';

		let today = new Date().toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris', hour: '2-digit'});
		
		let hour = parseInt(today.replace(/\D/g, ""));

		if (hour > 6 && hour <= 8) {
			power = 'reading';
		} else if (hour > 8 && hour <= 18) {
			power = 'battery';
		} else if (hour > 18 && hour < 23) {
			power = 'charge';
		}

		this.setState({
			power: power
		});
	}

	setTimePassed() {
		let date = new Date('1998-10-15T20:47:00+02:00').toUTCString();
		let utcString = new Date().toUTCString();

		let timeDifference = new Date(utcString).getTime() - new Date(date).getTime()

		let seconds = Math.floor(timeDifference / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);
		let days = Math.floor(hours / 24);
		let months = Math.floor(days / 30.4167);
		let years = Math.floor(months / 12);

		let remainingMonths = ('0' + Math.ceil(months % 12)).slice(-2);
		let remainingDays = ('0' + Math.ceil(days % 30.4167)).slice(-2);
		let remainingHours = ('0' + Math.ceil(hours % 24)).slice(-2);
		let remainingMinutes = ('0' + Math.ceil(minutes % 60)).slice(-2);
		let remainingSeconds = ('0' + Math.ceil(seconds % 60)).slice(-2);

		this.setState({
			years: years,
			days: remainingMonths + "-" + remainingDays,
			uptime: remainingHours + ':' + remainingMinutes + ':' + remainingSeconds
		});
	}

	drawCurve(canva, data) {
		let canvas = document.getElementById(canva);
		let context = canvas.getContext('2d');

		context.clearRect(0, 0, canvas.width, canvas.height);
		
		context.beginPath();
		context.strokeStyle = '#3333FF';
		context.lineWidth = 2;
		
		for (let i = 0; i < data.length && i < canvas.width/10; i++) {
			context.lineTo(canvas.width - (i * 10), data[data.length - i]);
		}
		
		context.stroke();

		let newData = data.push((Math.random() * (0.8 *canvas.height)) + (0.1 *canvas.height));

		this.setState({
			[data]: newData
		});
	}

	render() {
		return (
			<>
				<GraduatedLine />
				
				<div className='blaze'>
					Aldwin Poulain
				</div>
				
				<GraduatedLine />

				<div className='colomn-list'>
					<div className='colomn'>
						<span>{this.state.years} y.</span>
						<br/>
						{this.state.days}
					</div>
					<div className='colomn'>
						<span>uptime</span>
						<br/>
						{this.state.uptime}
					</div>
					<div className='colomn'>
						<span>type</span>
						<br/>
						dragon
					</div>
					<div className='colomn'>
						<span>power</span>
						<br/>
						{this.state.power}
					</div>
				</div>
				
				<GraduatedLine />
				
				<div className='colomn-list'>
					<div className='colomn'>
						manufacturer
						<br/>
						<span>mes parents</span>
					</div>
					<div className='colomn'>
						model
						<br/>
						<span>homme</span>
					</div>
				</div>
				
				<GraduatedLine />
				
				<div className='colomn-list brain'>
					<div className='cpu'>
						<span>cpu usage</span>
						<div className='cpu-name'>Body®Brain A10-1998P</div>
					</div>
					<div className='cpu-data'>
						<span>#1</span>
						<canvas id='canvas1'></canvas>
					</div>
					<div className='cpu-data'>
						<span>#2</span>
						<canvas id='canvas2'></canvas>
					</div>
					<hr/>
					<div className='colomn-list'>
						<div className='colomn'>
							<span>temp</span>
							<br/>
							37,5°C
						</div>
						<div className='colomn'>
							<span>min</span>
							<br/>
							à voir
						</div>
						<div className='colomn'>
							<span>max</span>
							<br/>
							à voir
						</div>
						<div className='colomn'>
							<span>tasks</span>
							<br/>
							29
						</div>
					</div>
				</div>
				
				<GraduatedLine />
			</>
		);
	}
}