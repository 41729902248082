import React from 'react';
import ReactApp from '../..';

import './Charging.scss';

export default class Charging extends React.Component {
	constructor(props){
		super(props);
	
		this.state = {
			title: '',
			charging: [],
			indexPhrase: 0
		}
	}

	componentDidMount() {
		this.setChargingTitle();
		this.updateCharging();

		this.title = setInterval(() => {
			this.setChargingTitle();
		}, 200);

		this.chargingText = setInterval(() => {
			this.updateCharging();
		}, 10);
	}

	componentWillUnmount() {
		clearInterval(this.title);
	}

	setChargingTitle() {
		ReactApp.setTitle('Charging' + this.state.title);

		if (this.state.title === '...') {
			this.setState({
				title: ''
			});
		} else {
			this.setState({
				title: this.state.title + '.'
			});
		}
	}

	updateCharging() {
		if (this.props.phrases[this.state.indexPhrase] === undefined) {
			clearInterval(this.chargingText);
			setTimeout(() => {
				this.props.update(false);
			}, 1000);
			return;
		}

		let charging = this.state.charging;

		if (charging[this.state.indexPhrase] === undefined) {
			charging[this.state.indexPhrase] = '';
		}
		
		charging[this.state.indexPhrase] = charging[this.state.indexPhrase].concat(this.props.phrases[this.state.indexPhrase][charging[this.state.indexPhrase].length]);
		
		this.setState({
			charging: charging
		});

		if (this.props.phrases[this.state.indexPhrase][charging[this.state.indexPhrase].length] === undefined) {
			this.setState({
				indexPhrase: this.state.indexPhrase + 1
			});
		}
	}

	render() {
		return (
			<div className='charging'>
				Aldwin.Poulain:-$ sudo ./load.sh profil
				<br />
				{this.state.charging.map((phrase, i) =>
					<React.Fragment key={i}>
						<span>{phrase}</span>
						<br />
					</React.Fragment>
				)}
			</div>
		);
	}
}