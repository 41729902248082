import React from 'react';

import Section from '@Components/section/Section';

import './About.scss';

export default class About extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		}
	}

	render() {
		return (
			<>
				<div className='about'>
					<h1 className='title'>Mon parcours professionnel</h1>
					<Section
						poste='Ingénieur logiciels'
						entreprise='Capgemini'
						temps='sept. 2022 - aujourd’hui'
						lieu='Brest, Bretagne, France'
						image='./assets/logoCapgemini.jpg'
					/>
					<Section
						poste='Développeur (alternance)'
						entreprise='OpenFly'
						temps='sept. 2021 - août 2022'
						lieu='Brest, Bretagne, France'
						image='./assets/logoOpenfly.jpg'
					/>
					<Section
						poste='Développeur (stage)'
						entreprise='W3'
						temps='mai 2021 - juil. 2021'
						lieu='Brest, Bretagne, France'
						image='./assets/logoW3.jpg'
					/>
					<Section
						poste='Développeur (stage)'
						entreprise='Makina Corpus'
						temps='juin 2020 - juil. 2020'
						lieu='Toulouse, Occitanie, France'
						image='./assets/logoMakina.jpg'
					/>
					<div className='lightColor'>
						<h1 className='title'>Mes compétences</h1>
						<div className='box-container'>
							<div className='box'>
								<h3>Technologies Web</h3>
								<div>
									PHP<br/>
									CSS<br/>
									HTML<br/>
									JavaScript<br/>
									VueJS<br/>
									ReactJS<br/>
									SCSS
								</div>
							</div>
							<div className='box'>
								<h3>Langages de programmation</h3>
								<div>
									Java<br/>
									C / C++<br/>
									SQL<br/>
									OpenGL<br/>
									NXT2.0<br/>
									Ocaml<br/>
									Python<br/>
									SmallTalk<br/>
									VisualBasic<br/>
									Go
								</div>
							</div>
							<div className='box'>
								<h3>Bases de données</h3>
								<div>
									Oracle SQL<br/>
									MySQL<br/>
									MariaDB<br/>
									neo4J<br/>
									MongodB
								</div>
							</div>
							<div className='box'>
								<h3>Outils & Frameworks</h3>
								<div>
									Laravel<br/>
									JEE (servlet Tomcat)<br/>
									JEE (JSP)<br/>
									CodeIgniter<br/>
									Eclipse<br/>
									Netbeans<br/>
									arduino<br/>
									Git<br/>
									Node.js
								</div>
							</div>
							<div className='box'>
								<h3>Méthodes d'analyse & de conception</h3>
								<div>
									Scrum<br/>
									Agile
								</div>
							</div>
							<div className='box'>
								<h3>Autres</h3>
								<div>
									Français<br/>
									Breton<br/>
									Anglais<br/>
									Espagnol
								</div>
							</div>
						</div>
					</div>
					<h1 className='title'>Ma formation</h1>
					<Section
						poste='Master 2 Informatique Ingénierie du Logiciel, Applications aux Données Environnementales / Alternance'
						entreprise='Université de Bretagne Occidentale / W3'
						temps='2021 - 2022'
						lieu='Brest / Guipavas'
					/>
					<Section
						poste='Master 1 ILIADE'
						entreprise='Université de Bretagne Occidentale'
						temps='2020 - 2021'
						lieu='Brest'
					/>
					<Section
						poste='Licence Informatique Fondements et Applications'
						entreprise='Université de Bretagne Occidentale'
						temps='2017 - 2020'
						lieu='Brest'
					/>
					<Section
						poste='Baccalauréat S'
						entreprise='Lycée Diwan'
						temps='2015 - 2016'
						lieu='Carhaix'
					/>
				</div>
			</>
		);
	}
}