import ReactDOM from 'react-dom/client';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('app'));
let defaultTitle = 'Aldwin Poulain';
let title;

export default class ReactApp {

	static setTitle(value, isError = false) {
		if (isError) {
			title = value;
		} else {
			title = defaultTitle + ' - ' + value;
		}
		
		renderReactApp();
	}

	static isPhoneView() {
		return window.innerWidth <= 600;
	}
}

function renderReactApp() {
	root.render(
		<App
			title={title}
		/>
	);
}

renderReactApp();

window.onresize = renderReactApp();