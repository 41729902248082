import React from 'react';

import './Section.scss';

export default class Section extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		}
	}

	render() {
		return (
			<div className='section'>
				{!!this.props.image &&
					<img src={this.props.image} alt=''/>
				}
				<div className='section-data'>
					<b>
						{this.props.poste}
					</b>
					<div>
						{this.props.entreprise}
					</div>
					<div>
						{this.props.temps}
					</div>
					<div>
						{this.props.lieu}
					</div>
				</div>
			</div>
		);
	}
}