import React from 'react';

import './GraduatedLine.scss';

export default class GraduatedLine extends React.Component {
	constructor(props){
		super(props);
	
		this.state = {
		}
	}
	render() {
		return (
			<>
				<div className='graduations-container'>
					<div className='graduation' style={{left: '0'}}></div>
					<div className='graduation' style={{left: '100%'}}></div>
					<hr />
				</div>
			</>
		);
	}
}