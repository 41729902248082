import React from 'react';

import './Footer.scss';

export default class Footer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		}
	}

	render() {
		return (
			<footer>
				<div className='credits'>
					© 2023 Aldwin Poulain
				</div>
			</footer>
		);
	}
}
