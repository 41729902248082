import React from 'react';
import {Helmet} from 'react-helmet';
import ReactApp from '.';

import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';

import Footer from '@Components/footer/Footer';
import Toolbar from '@Components/toolbar/Toolbar';
import HumanData from '@Components/humanData/HumanData';
import GraduatedLine from '@Components/graduatedLine/GraduatedLine';

import './variables.scss';
import './App.scss';
import Charging from './components/charging/Charging';

export default class App extends React.Component {
	constructor(props){
		super(props);

		this.updateContent = this.updateContent.bind(this);
		this.updateCharging = this.updateCharging.bind(this);
	
		this.state = {
			component: <Home/>,
			terminal: 'Accueil',
			menu: [
					{element: <Home/>, name: 'Accueil', active: true},
					{element: <About/>, name: 'À propos', active: false},
					//{element: <div>à faire</div>, name: 'Mes projets', active: false},
					{element: <Contact/>, name: 'Contacts', active: false}
				],
			isCharging: true
		}
	}

	updateContent(element) {
		let menu = this.state.menu;
		menu.forEach(element => element.active = false);

		let terminal = menu.filter(menuItem => menuItem.element === element);
		terminal[0].active = true;

		this.setState({
			component: element,
			terminal: terminal[0].name,
			menu: menu
		});

		ReactApp.setTitle(terminal[0].name);
	}

	updateCharging(value) {
		this.setState({
			isCharging: value
		});
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{this.props.title}</title>
				</Helmet>
				
				<div className='content'>
					<div className='panel'>
						<div className='panel-header'>
							<span>panel</span>
							<span>system</span>
						</div>
						<GraduatedLine />
						<div className='panel-content'>
							<HumanData />
						</div>
					</div>
					
					<div className='panel panel-center'>
						<div className='panel-header'>
							<span>terminal</span>
							<span>{this.state.terminal}</span>
						</div>
						<GraduatedLine />
						<div className='content-container'>
							{this.state.isCharging ?
								<Charging
									update={this.updateCharging}
									phrases={[
										'Initialisation du profile...',
										'├─Allumage du système',
										'├─Chargement du CPU',
										'├─[############################################################]',
										'│├─Hémisphère droit démarré',
										'│├─Hémisphère gauche démarré',
										'│└Chargement du CPU complété',
										'├─[############################################################]',
										'├─Chargement du parcours professionnel',
										'├─Chargement des compétences',
										'├─Chargement de la formation',
										'└Profile complété !',
									]}
								/>
							:
								<>
									<Toolbar menuItems={this.state.menu} update={this.updateContent} />
									<div className='container'>
										{this.state.component}
									</div>
								</>
							}
						</div>
					</div>
						
					<div className='panel'>
						<div className='panel-header'>
							<span>panel</span>
							<span>network</span>
						</div>
						<GraduatedLine />
						<div className='panel-content others'>
							<h1 className='title'>Mes passions</h1>
							<div>La programmation</div>
							<div>L'escrime</div>
							<div>Les jeux</div>
							<div>La marche</div>
							<div>La modélisation 3d</div>
						</div>
					</div>
				</div>
				
				<Footer />
			</>
		);
	}
}