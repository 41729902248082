import React from 'react';

import './Contact.scss';

export default class Contact extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		}
	}

	render() {
		return (
			<div className='contact'>
				<div className='contact-container'>
					<div className='contact-item'>
						<b className='title'>
							téléphone
						</b>
						<div>
							07.77.trente-sept.85.vingt-neuf
						</div>
					</div>
					<div className='contact-item'>
						<b className='title'>
							e-mail
						</b>
						<div>
							aldwinpm arobase outlook[dot]fr
						</div>
					</div>
				</div>
				<div className='contact-container'>
					<div className='contact-item'>
						<b className='title'>
							linkedin
						</b>
						{/*eslint-disable-next-line*/}
						<a href='https://www.linkedin.com/in/aldwin-poulain-626696205/' target='_blank'>
							<img src='./assets/linkedin_qr-code.svg' alt=''/>
						</a>
					</div>
				</div>
			</div>
		);
	}
}