import React from 'react';
//import ReactApp from '_funcsJS';

import './Toolbar.scss';

export default class Toolbar extends React.Component {
	constructor(props) {
		super(props);

		this.toggleMenu = this.toggleMenu.bind(this);

		this.state = {
			isMenuOpen: false,
		}
	}

	toggleMenu() {
		this.setState({
			isMenuOpen: !this.state.isMenuOpen
		});
	}

	render() {
		return (
			<>
				<div className='toolbar'>
					<ul>
					{this.props.menuItems.map((item, i) =>
						<li className={'nav-item' + (item.active ? ' active' : '')} key={i} onClick={() => this.props.update(item.element)}>
							<div className='item'>
								<span>{item.name}</span>
							</div>
						</li>
					)}
					</ul>
				</div>
			</>
		);
	}
}