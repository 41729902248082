import React from 'react';

import ReactApp from '_funcsJS';

import './Home.scss';

export default class Home extends React.Component {
	constructor(props) {
		super(props);
	
		this.state = {

		}
	}

	componentDidMount() {
		ReactApp.setTitle('Accueil');
	}

	render() {
		return (
			<div className='home'>
				<img src='./assets/profile.png' alt=''/>
				<div className='description'>
					<h1>Qui suis-je ?</h1>
					<p>Je suis un jeune développeur.</p>
				</div>
			</div>
		)
	}
}